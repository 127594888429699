
<template>
  <v-sheet rounded="xl" class="pa-6 text-start" maxWidth="586px">
    <slot name="content"> </slot>
    <div class="d-flex justify-center text-caption mb-9">
      <v-img max-width="32px" contain :src="require(`@/assets/binance.svg`)" />
      <div class="ml-2 text-body-1 align-self-center">Binance Smart Chain network</div>
    </div>
    <v-btn rounded color="primary" block large depressed @click="connectWallet" :loading="connectingWallet" class="mb-6"
      >Connect Wallet</v-btn
    >
  </v-sheet>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    url: String,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('auth', ['account', 'connectingWallet']),
  },
  methods: {
    ...mapActions('auth', ['connectMetamask']),
    async connectWallet() {
      try {
        await this.connectMetamask()
        this.$router.push(this.url)
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

