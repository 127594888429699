<template>
  <ConnectWalletBase url="pools">
    <template v-slot:content>
      <div class="d-flex flex-column align-center mb-1">
        <v-img
          alt="Vuetify Logo"
          contain
          :src="require(`@/assets/multi-claimer.svg`)"
          transition="scale-transition"
          max-width="100px"
        />
      </div>
      <div class="text-lg text-center">MultiClaimer</div>
      <div class="text-caption text-caption my-4">Wellcome to Multiclaimer! <br /></div>
      <div class="text-caption mb-9">
        Creating and sending token to a list of the multiple address according to the set time. <br />
        Multiple address check & claim on public page. <br />
        Allowing all ERC20 tokens including Rebasing and Deflationary mechanisms to be supported.
      </div>
    </template>
  </ConnectWalletBase>
</template>
<script>
import ConnectWalletBase from '../ConnectWalletBase.vue'
export default {
  props: {
    url: String,
  },
  components: {
    ConnectWalletBase,
  },
  data() {
    return {}
  },
}
</script>